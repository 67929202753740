<template>
  <v-dialog
      max-width="600px"
      v-model="dialog"
      @input="get_circles"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs"
             v-on="on"
      >
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-icon v-if="!big_btn" v-bind="attrs" small
              v-on="on" class="mx-4">
        {{ activator_icon }}
      </v-icon>
    </template>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="code" name="Zone Code" rules="required" v-slot="{ errors }">
                  <v-text-field
                      label="Code*"
                      hint="Zone Code"
                      required
                      autocomplete="nope"
                      v-model="zone.code"
                      maxlength="20"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="name" name="Zone name" rules="required" v-slot="{ errors }">
                  <v-text-field
                      label="Name*"
                      hint="Zone Name"
                      required
                      autocomplete="nope"
                      v-model="zone.name"
                      maxlength="256"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="circle" name="Circle name" rules="objectNotEmpty:code|required"
                                    v-slot="{ errors }">
                  <v-select label="Select a circle*" v-model="zone.circle" hint="The Circle where this zone resides"
                            :items="circles" item-text="name" clearable
                            item-value="id" item-color="primary">

                  </v-select>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"

export default {
  name: "add_edit_form",
  props: ['passed_item', 'icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    zone: {
      code: '',
      name: '',
      id: '',
      circle: {
        name: '',
        code: '',
        id: ''
      }
    },
    circles: [{name: 'test', code: 'test', id: 'test'}],
    circle_loading: false
  }),
  methods: {
    async add_new_zone() {
      this.axios_conf
      let data = {code: this.zone.code, name: this.zone.name, circle: this.zone.circle}
      // console.log(this.zone.circle)
      await axios.post('user/zonal_office', data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.$refs.vform.reset()
            this.$refs.vform.resetValidation()
            this.dialog = false
            this.$emit('form_status', true)
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                code: error.response.data.errors.code,
                name: error.response.data.errors.name,
                circle: error.response.data.errors.circle,
              });
            }
          })
      this.submit_loading = false
    },
    async edit_zone() {
      this.axios_conf
      // console.log(this.zone)
      let data = {code: this.zone.code, name: this.zone.name, circle: this.zone.circle}
      if (this.zone.circle.id) {
        data.circle = this.zone.circle.id
      }
      await axios.patch(`user/zonal_office/${this.zone.id}/update`, data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            // this.$refs.vform.reset()
            // this.$refs.vform.resetValidation()
            this.dialog = false
            this.$emit('form_status', true)
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                code: error.response.data.errors.code,
                name: error.response.data.errors.name,
                circle: error.response.data.errors.circle,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new_zone()
      } else if (this.action === 'edit') {
        this.edit_zone()
      }
    },
    async get_circles() {
      this.axios_conf
      this.circle_loading = true
      await axios.get("user/circle_office")
          .then((resp) => {
            this.circles = resp.data.circles
            this.$store.commit('setJWT', resp.data.key)
          })
      this.circle_loading = false
    },
  },
  watch: {
    passed_item(val) {
      // console.log(val)
      this.zone = JSON.parse(JSON.stringify(val))
      // console.log(this.zone)
    },
    // zone(val) {
    //   // console.log(val)
    // }
  },
  mounted() {
    if (this.passed_item) {
      this.zone = JSON.parse(JSON.stringify(this.passed_item))
    }
  }
}
</script>

<style scoped>
</style>