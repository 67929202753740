<template>
  <v-container v-if="has_view_permission">
    <v-card class="my-1 pa-3 blue accent-1">
      <v-card-title>
        <add_edit_form v-if="add_button" activator_name="Add New Zonal Office"
                       form_title="Add New Zonal Office"
                       activator_icon="mdi-plus-thick" action="add" @form_status="get_zones"
                       big_btn="true"></add_edit_form>
        <v-btn :loading="reload_btn" @click="get_zones" class="ma-1">
          <v-icon>
            mdi-reload
          </v-icon>
          Reload Data Table
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card class="yellow lighten-2">
      <v-data-table
          :headers="column_headers"
          :items="table_items"
          sort-by="code"
          class="elevation-5 yellow lighten-2"
          :loading="table_loading"
          :search="search"
          loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-card-title
              flat
          >
            Zonal Office List
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
        </template>
        <template v-slot:item="{item}">
          <tr>
            <td class="text-center">
              {{ item.code }}
            </td>
            <td class="text-center">
              {{ item.name }}
            </td>
            <td class="text-center">
              {{ item.circle.name }}
            </td>
            <td class="text-center">
              <add_edit_form v-if="edit_button"
                             form_title="Edit Zonal Office" :passed_item="item"
                             activator_icon="mdi-pencil" action="edit" @form_status="get_zones"
              ></add_edit_form>
              <delete_dialog v-if="delete_button" @form_status="get_zones" :zone="item"></delete_dialog>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import add_edit_form from "@/components/office_components/zonal_sub_components/add_edit_form";
import delete_dialog from "@/components/office_components/zonal_sub_components/delete_dialog.vue";


export default {
  name: "ZoneListTable",
  components: {add_edit_form, delete_dialog},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('users.view_zonaloffice')
    },
    edit_button() {
      return this.$store.getters.permissionCheckers('users.change_zonaloffice')
    },
    delete_button() {
      return this.$store.getters.permissionCheckers('users.delete_zonaloffice')
    },
    add_button() {
      return this.$store.getters.permissionCheckers('users.add_zonaloffice')
    },
  },
  data: () => ({
    reload_btn: false,
    table_loading: false,
    column_headers: [
      {
        text: 'Zone Code',
        align: 'center',
        filterable: true,
        value: 'code',
      },
      {
        text: 'Zone Name',
        align: 'center',
        filterable: true,
        value: 'name',
      },
      {
        text: 'Circle Name',
        align: 'center',
        filterable: true,
        value: 'circle.name',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: '',
      },
    ],
    table_items: [],
    search: '',
  }),
  methods: {
    async get_zones() {
      this.axios_conf
      this.reload_btn = true
      this.table_loading = true
      await axios.get("user/zonal_office").then((resp) => {
        // console.log(resp)
        this.table_items = resp.data.zones
        this.$store.commit('setJWT', resp.data.key)
      })
      this.reload_btn = false
      this.table_loading = false
    },
  },
  mounted() {
    this.get_zones();
  }
}
</script>

<style scoped>
tbody tr:hover {
  background: #82B1FF !important;
}
</style>