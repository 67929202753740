<template>
  <v-container>
    <ZoneListTable></ZoneListTable>
  </v-container>
</template>

<script>
import ZoneListTable from "@/components/office_components/ZoneListTable";

export default {
  name: "ZoneListPage",
  components: {ZoneListTable},
  mounted() {
    document.title = 'Agrani Bank Legal Suit System | Zone List';
  }
}
</script>

<style scoped>

</style>